import { useState, useEffect } from "react";
import axios from "axios";

import { useSearchParams } from "react-router-dom";

function TransactionLoader() {

  return (
    <div class="loader">
      {/* <h1> TransactionStatus: {transaction?.status} </h1>
      <p> Amount: {transaction?.amount}</p>
      <p> Description: {transaction?.description}</p> */}
    </div>
  );
}

export default TransactionLoader;
